import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';

class Layout extends ReactQueryParams {
    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(Layout);