import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AppProvider } from '@shopify/polaris';
import Auth from './containers/auth/';
import Install from './containers/auth/install';
import Paid from './containers/auth/paid';
import Layout from './containers/layout/';
import Logout from './containers/auth/logout';
import { fb, db } from './actions/settings';
import { login, logout } from './actions';

// layout routes
import Home from './containers/home';
import BuildForm from './containers/builds/form';
import AddProducts from './containers/products/add';

class App extends React.Component {

  componentDidMount = () => {
    fb.auth().onAuthStateChanged((user) => {
      if (user) {
        if (this.props.user.info) {
          db.collection("Shops")
          .doc(this.props.user.info.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              if (doc.data().installed) {
                this.props.login(doc.data());
              } else {
                this.props.logout();
              }
            } else {
              this.props.logout();
            }
          })
          .catch((err) => {
            console.log("ERROR", err);
            this.props.logout();
          });
        } else {
          this.props.logout();
        }
      } else {
        this.props.logout();
      }
    });
  }

  requireAuth = () => {
    return this.props.user.loggedIn && this.props.user.info ? true : false;
  }
  
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/app/" render={() => (
            this.requireAuth() ? (
              <AppProvider 
                apiKey="dbd4ec51ee647018f63c052f959bac0d" 
                shopOrigin={this.props.user.info.shop}
                forceRedirect={true}
              >
                <Layout>
                  <Route exact path="/app/" component={Home}/>
                  <Route exact path="/app/builds/form" component={BuildForm}/>
                  <Route exact path="/app/builds/form/:id" component={BuildForm}/>
                  <Route exact path="/app/products/add" component={AddProducts}/>
                </Layout>
              </AppProvider>
            ) : (
              <Redirect to="/logout"/>
            )
          )}/>
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/install" component={Install} />
          <Route exact path="/paid" component={Paid} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
  login,
  logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);