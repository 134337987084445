import React from 'react';
import { AppProvider, Spinner } from '@shopify/polaris';
import { css } from '@emotion/core';

export const LoadingPage = ({shop}) => {
    const override = css`   
        left: 50%;
        height: 100%;
        top: 50%;
        margin-top: -50px;
        margin-left: -25px;
        position: absolute;
    `;
    return (
        <div style={{backgroundColor:'#f4f6f8',height:'100%',margin:'auto',width:'100%',position:'absolute'}}>
            <div style={{left: 50+'%', height: 100+'%', top: 50+'%', marginTop:-50+'px', marginLeft: -25+'px', position: 'absolute'}}>
                {shop !== "" && 
                <AppProvider 
                    apiKey="dbd4ec51ee647018f63c052f959bac0d" 
                    shopOrigin={shop}
                    forceRedirect={true}
                >
                    <Spinner size="large" color="teal" />
                </AppProvider>
                }
                {shop === "" && 
                <AppProvider 
                    apiKey="dbd4ec51ee647018f63c052f959bac0d" 
                    forceRedirect={true}
                >
                    <Spinner size="large" color="teal" />
                </AppProvider>
                }
            </div>
        </div>
    )
}