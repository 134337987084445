import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { login, checkAuth } from './../../actions';
import { fb } from './../../actions/settings';
import { ErrorPage } from './../auth/error';
import { LoadingPage } from './../misc/loading';

class Auth extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            info: null,
            install_url: null,
            has_auth: false
        }
    }

    componentDidMount() {
        checkAuth(this.queryParams)
        .then((res) => {
            this.props.login(res.user);
            this.setState({
                loading: false,
                info: res.user,
                has_auth: res.has_auth,
                install_url: res.install_url
            });
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                loading: false,
                has_auth: false
            });
        });
    }

    render() {
        let { history } = this.props;
        let { has_auth, install_url, info, loading } = this.state;

        if (!loading) {
            if (has_auth) {
                if (install_url) {
                    window.location.href = install_url;
                } else {
                    try {
                        fb
                        .auth()
                        .signInWithEmailAndPassword(info.email, info.access)
                        .then((user) => {
                            if (user) {
                                history.push("/app/");
                            }
                        })
                        .catch((err) => console.log(err));
                    } catch(e) {
                        console.log(e);
                        return <ErrorPage title="Invalid Request." message="We couldn't authenticate you, please try again."/>;
                    }
                }
                return null;
            } else {
                return <ErrorPage title="Invalid Request." message="We couldn't authenticate you, please try again."/>
            }
        } else {
            return <LoadingPage shop=""/>
        }
    }
}

const mapStateToProps = state => {
    return state;
}
  
const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Auth);