import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyB7oaIiiqme_TocKuh66l_Ilv-djyBHQ2U",
    authDomain: "build-a-box.firebaseapp.com",
    databaseURL: "https://build-a-box.firebaseio.com",
    projectId: "build-a-box",
    storageBucket: "build-a-box.appspot.com",
    messagingSenderId: "838057628376",
    appId: "1:838057628376:web:277bded77c70cba3"
};
  
export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const db = app.firestore();