import React from 'react';
import { connect } from 'react-redux';
import { 
    Card, Layout, TextStyle, 
} from '@shopify/polaris';

class Stats extends React.Component {

    render() {
        return (
            <>
                <Layout.Section oneThird>
                    <Card title="Total Sales" actions={[{content: 'View'}]}>
                        <Card.Section>
                            <TextStyle variation="subdued">455 sales</TextStyle>
                        </Card.Section>
                        <Card.Section title="Recent Sales">

                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section oneThird>
                    <Card title="Revenue Generated">
                        <Card.Section>
                            <TextStyle variation="subdued">$1,044.34 to date</TextStyle>
                        </Card.Section>
                        <Card.Section title="Recent Months">

                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section oneThird>
                    <Card title="Number of Builds">
                        <Card.Section>
                            <TextStyle variation="subdued">4 Builds Created</TextStyle>
                        </Card.Section>
                        <Card.Section title="Most Recent">

                        </Card.Section>
                    </Card>
                </Layout.Section>
            </>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(Stats);