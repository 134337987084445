
const defaultState = {
    info: null,
    loggedIn: false,
    stage: null
}

export default function user (state = defaultState, action) {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                loggedIn: true,
                info: action.payload.info
            }
        case "LOGOUT":
            return {
                ...state,
                info: null,
                loggedIn: false
            }
        case "STAGE_STATE":
            return {
                ...state,
                stage: action.payload.data
            }
        default:
            return state;
    }
}