import React from 'react';
import ReactDOM from 'react-dom';

// redux imports
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import promiseMiddleware from 'redux-promise';

import reducers from './reducers';

import App from './App';

import { ToastProvider } from 'react-toast-notifications';

const persistConfig = {
    key: 'root',
    storage
}
const persistedReducer = persistReducer(persistConfig, reducers);
const storeWithMiddleware = applyMiddleware(promiseMiddleware)(createStore)(persistedReducer);
const persistor = persistStore(storeWithMiddleware);

ReactDOM.render(
    <Provider store={storeWithMiddleware}>
        <PersistGate persistor={persistor}>
            <ToastProvider placement="bottom-center">
                <App/>
            </ToastProvider>
        </PersistGate>
    </Provider>, document.getElementById('root')
);