import React from 'react';
import { connect } from 'react-redux';
import { Page, Layout, TextField, ResourcePicker, Card, Checkbox, Button,
    TextContainer, Heading
} from '@shopify/polaris';
import { LoadingPage } from './../misc/loading';

class AddProducts extends React.Component {
    
    state = {
        loading: true,
        hasMin: false,
        hasMax: false,
        stage: {
            name: '',
            order: '',
            hasMax: false,
            hasMin: false,
            min: 1,
            max: 1,
            products: []
        },
        resourcePickerOpen: false
    }
    
    componentDidMount = () => {
        localStorage.setItem('stage', JSON.stringify(this.state.stage));
        this.setState({
            loading: false
        });
    }

    updateStageState = (data) => {
        this.setState({
            stage: {...this.state.stage, [data[0]]: data[1]}
        }, () => {
            localStorage.setItem('stage', JSON.stringify(this.state.stage));
        });
    }

    render() {
        let { loading } = this.state;
        const VariantPicker = <ResourcePicker
                resourceType="Product"
                open={this.state.resourcePickerOpen}
                onSelection={({selection}) => {
                    console.log('Selected products: ', selection);
                    this.updateStageState(["products", selection]);
                    this.setState({
                        resourcePickerOpen: false
                    });
                }}
                onCancel={() => this.setState({resourcePickerOpen: false})}
            />
        if (loading) {
            return <LoadingPage shop={this.props.user.info.shop}/>
        } else {
            return (
                <Page
                    title="Setup your Build-A-Box Stage"
                >
                    <Layout>
                        <Layout.Section>
                            <TextField
                                value={this.state.stage.name}
                                onChange={(text) => this.updateStageState(["name", text])}
                                label="Stage Name"
                                type="text"
                                helpText={<span>This is shown to the customer.</span>}
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <TextField
                                value={this.state.stage.order}
                                onChange={(text) => this.updateStageState(["order", text])}
                                label="Stage Display Order"
                                type="number"
                                helpText={<span>The order in which the customer moves through the stages.</span>}
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <Checkbox
                                checked={this.state.hasMin}
                                label="Required Stage?"
                                onChange={() => {
                                    this.setState({hasMin: !this.state.hasMin});
                                    this.updateStageState(["hasMin", !this.state.hasMin]);
                                    this.updateStageState(["min", 0]);
                                }}
                            />
                            <TextField
                                value={this.state.stage.min}
                                disabled={!this.state.hasMin}
                                onChange={(text) => this.updateStageState(["min", text])}
                                label="Minimum Number of Selections Required"
                                type="number"
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <Checkbox
                                checked={this.state.hasMax}
                                label="Set Maximum Selections Allowed"
                                onChange={() => {
                                    this.setState({hasMax: !this.state.hasMax});
                                    this.updateStageState(["hasMax", !this.state.hasMax]);
                                    this.updateStageState(["max", '']);
                                }}
                            />
                            <TextField
                                value={this.state.stage.max}
                                disabled={!this.state.hasMax}
                                onChange={(text) => this.updateStageState(["max", text])}
                                label="Maximum Number of Selections Allowed"
                                type="number"
                            />
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section>
                            <div style={{marginTop:25+'px'}}>
                                <Heading>Add the products available in this stage.</Heading>
                                <Button onClick={() => this.setState({resourcePickerOpen: true})}>Add product</Button>
                                <TextContainer><p style={{marginTop:10+'px'}}>{this.state.stage.products.length} Products Added</p></TextContainer>
                                {VariantPicker}
                            </div>
                        </Layout.Section>
                    </Layout>
                </Page>
            )
        }
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(AddProducts);