import React from 'react';
import { SkeletonPage, Layout, Card, SkeletonBodyText } from '@shopify/polaris';

export default class Loading extends React.Component {

    render() {
        return (
            <SkeletonPage>
                <Layout>
                    <Layout.Section oneThird>
                        <Card sectioned title="Total Sales">
                            <SkeletonBodyText />
                        </Card>
                    </Layout.Section>
                    <Layout.Section oneThird>
                        <Card sectioned title="Revenue Generated">
                            <SkeletonBodyText />
                        </Card>
                    </Layout.Section>
                    <Layout.Section oneThird>
                        <Card sectioned title="Number of Builds">
                            <SkeletonBodyText />
                        </Card>
                    </Layout.Section>
                </Layout>
                <div style={{marginTop: 25+'px'}}>
                    <Layout>
                        <Layout.Section>
                            <Card sectioned title="Your Build-A-Box Pages">
                                <SkeletonBodyText />
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            </SkeletonPage>
        )
    }
}