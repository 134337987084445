import React from 'react';
import { connect } from 'react-redux';
import { ResourceList, Page, Layout, TextField, Card, Badge, TextStyle,
    Heading, PageActions, Button, Icon, TextContainer, Toast, Spinner,
    Checkbox
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { Modal, Button as bridgeButton } from '@shopify/app-bridge/actions';
import { saveStage } from './../../actions/';
import { db } from './../../actions/settings';
import { LoadingPage } from './../misc/loading';

class BuildForm extends React.Component {
    
    state = {
        loading: true,
        saving: false,
        completing: false,
        complete: false,
        error: false,
        errorMsg: '',
        stages: [],
        modal: false,
        active: true,
        build: {
            name: '',
            description: '',
            number: 1
        }
    }

    componentDidMount = () => {
        let randomstring = require("randomstring");
        let buildId = randomstring.generate(16);
        if (this.props.match.params.id) {
            buildId = this.props.match.params.id;
            this.setState({
                buildId: buildId
            }, () => {
                db.collection("Builds")
                .doc(buildId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        this.getStages();
                        this.setState({
                            loading: false,
                            buildId: buildId,
                            active: doc.data().active,
                            build: { 
                                name: doc.data().name,
                                description: doc.data().description,
                                number: doc.data().number
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                        stages: []
                    });
                });
            });
        } else {
            this.setState({
                loading: false,
                buildId: buildId,
                stages: []
            });
        }
    }

    showOnStore = () => {
        console.log("Off we go.")
    }

    loadStage = (stage) => {
        console.log(stage);
    }

    deleteStage = (id) => {
        db.collection("Stages").doc(id).delete()
        .then(() => {
            this.getStages();
        })
        .catch((err) => {
            console.log(err);
            this.getStages();
        });
    }

    getStages = () => {
        db.collection("/Stages/")
        .orderBy("order")
        .where("buildId", "==", this.state.buildId)
        .get()
        .then((docs) => {
            if (docs.size > 0) {
                let stages = [];
                docs.forEach((doc) => {
                    stages.push(doc.data());
                });
                this.setState({
                    stages: stages
                });
            } else {
                this.setState({
                    stages: []
                });
            }
        })
    }

    saveBuild = () => {
        let build = this.state.build;
        let stop = false, errorMsg = '';
        let { stages, buildId, active } = this.state;
        this.setState({
            completing: true
        }, () => {
            if (build.name === '') {
                errorMsg = "Missing Build Name";
                stop = true;
            }
            if (stages.length < 1) {
                errorMsg = "Create at least 1 stage";
                stop = true;
            }
            if (!stop) {
                db.collection("Builds")
                .where("shopId", "==", this.props.user.info.id)
                .get()
                .then((docs) => {
                    let number;
                    if (this.props.match.params.id) {
                        number = this.state.build.number;
                    } else {
                        number = docs.size + 1;
                    }
                    db.collection("Builds")
                    .doc(buildId)
                    .set({
                        id: buildId,
                        name: build.name,
                        description: build.description,
                        shopId: this.props.user.info.id,
                        active: active,
                        stagesLength: stages.length,
                        number: number,
                        link: `https://${this.props.user.info.domain}/tools/builder?b=${number}`
                    })
                    .then(() => {
                        this.setState({
                            complete: true
                        }, () => {
                            this.props.history.push('/app/')
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({
                            error: true,
                            errorMsg: "Unable to save, please try again.",
                            completing: false
                        });
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        error: true,
                        errorMsg: "Unable to save, please try again.",
                        completing: false
                    });
                });
            } else {
                this.setState({
                    error: true,
                    errorMsg: errorMsg,
                    completing: false
                });
            }
        });
    }

    openModal = () => {
        const app = createApp({
            apiKey: 'dbd4ec51ee647018f63c052f959bac0d',
            shopOrigin: this.props.user.info.shop
        });
        const okButton = bridgeButton.create(app, {label: 'Save Stage', loading: this.state.saving});
        okButton.subscribe(bridgeButton.Action.CLICK, () => {
            console.log(localStorage.getItem('stage'));
            this.setState({
                saving: true
            });
            var data = JSON.parse(localStorage.getItem('stage'));
            data.shopId = this.props.user.info.id;
            data.shop = this.props.user.info.shop;
            data.buildId = this.state.buildId;
            var stop = false, errorMsg = '';
            if (data.products.length < 1) {
                errorMsg = "You must select at least 1 product"
                stop = true;
            }
            if (data.hasMax && (isNaN(data.max) || data.max === "")) {
                errorMsg = "Missing Maximum Number of Selections Required"
                stop = true;
            }
            if (data.hasMin && (isNaN(data.min) || data.min == "")) {
                errorMsg = "Missing Minimum Number of Selections Required"
                stop = true;
            }
            if (data.order === "") {
                errorMsg = "Missing Display Order"
                stop = true;
            }
            if (data.name === "") {
                errorMsg = "Missing Stage Name"
                stop = true;
            }
            if (!stop) {
                saveStage(data)
                .then((res) => {
                    this.getStages();
                    this.setState({
                        saving: false
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
                myModal.dispatch(Modal.Action.CLOSE);
            } else {
                this.setState({
                    error: true,
                    errorMsg: errorMsg,
                    saving: false
                });
            }
        });
        const cancelButton = bridgeButton.create(app, {label: 'Cancel'});
        cancelButton.subscribe(bridgeButton.Action.CLICK, () => {
            myModal.dispatch(Modal.Action.CLOSE);
        });
        const modalOptions = {
            title: 'Add Products to Stage',
            path: '/app/products/add',
            size: Modal.Size.Large,
            footer: {
                buttons: {
                  primary: okButton,
                  secondary: [cancelButton],
                }
            }
        };
        const myModal = Modal.create(app, modalOptions);
        myModal.dispatch(Modal.Action.OPEN)
    }

    render() {
        let { loading, stages } = this.state;
        if (loading) {
            return <LoadingPage shop={this.props.user.info.shop}/>
        } else {
            return (
                <Page
                    breadcrumbs={[{content: 'Home', url: '/app/'}]}
                    title="Create a New Build"
                    titleMetadata={<Badge status="success">new build</Badge>}
                    secondaryActions={[
                        {
                            content: 'Preview on your store',
                            external: true,
                            url: this.props.user.info.domain,
                        }
                    ]}
                >
                    <Layout>
                        <Layout.Section>
                            <Heading>Setup your Build-A-Box Page</Heading>
                        </Layout.Section>
                        <Layout.Section>
                            <TextField
                                value={this.state.build.name}
                                onChange={(text) => this.setState({build: { ...this.state.build, name: text} })}
                                label="Page Title"
                                type="text"
                                helpText={<span>This will show at the top of the page.</span>}
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <TextField
                                value={this.state.build.description}
                                onChange={(text) => this.setState({build: { ...this.state.build, description: text} })}
                                label="Page Sub-Title/Description"
                                type="text"
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <Checkbox
                                checked={this.state.active}
                                label="Publish"
                                onChange={() => {
                                    this.setState({active: !this.state.active});
                                }}
                            />
                        </Layout.Section>
                        <Layout.Section>
                            <Card title="Add the different stages to your build" sectioned>
                                <TextContainer>
                                    <Button primary onClick={() => this.openModal()}>Add Stage</Button>
                                </TextContainer>
                            </Card>
                            {stages.length == 0 &&
                            <p style={{marginTop:15+'px'}}>0 stages added.</p>
                            }
                            {this.state.saving && 
                            <Spinner size="small" color="teal" />
                            }
                        </Layout.Section>
                        <Layout.Section>
                            <Card>
                                <ResourceList
                                    resourceName={{singular: 'stage', plural: 'stages'}}
                                    items={stages}
                                    renderItem={(item) => {
                                        const { id, name, products } = item;
                                        const actions = [
                                            {content: 'Edit Stage', onAction: () => this.loadStage(item)},
                                            {content: <Icon source="delete"/>, onAction: () => this.deleteStage(item.stageId)}
                                        ];
                                        return (
                                            <ResourceList.Item
                                                id={id}
                                                shortcutActions={actions}
                                                persistActions
                                            >
                                            <h3>
                                                <TextStyle variation="strong">{name}</TextStyle>
                                            </h3>
                                            <div>{products.length} Products Added</div>
                                            </ResourceList.Item>
                                        );
                                    }}
                                />
                            </Card>
                        </Layout.Section>
                    </Layout>
                    {this.state.error && 
                    <Toast content={this.state.errorMsg} error onDismiss={() => this.setState({error: false})} />
                    }
                    {this.state.complete && 
                    <Toast content="Saved Build" onDismiss={() => this.setState({complete: false})} />
                    }
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            loading: this.state.completing,
                            onAction: () => this.saveBuild()
                        }}
                        secondaryActions={[
                            {
                                content: 'Back',
                                onAction: () => this.props.history.push('/app/')
                            }
                        ]}
                    />
                </Page>
            )
        }
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(BuildForm);