import React from 'react';
import { connect } from 'react-redux';
import { logout } from './../../actions/';
import { LoadingPage } from './../../containers/misc/loading';
import { fb } from './../../actions/settings';

class Logout extends React.Component {

    componentDidMount() {
        this.props.logout();
        fb.auth().signOut().then(() => {
            window.location = "/";
        }, function(error) {
            // An error happened.
        });
    }

    render() {
        return <LoadingPage shop=""/>
    }
}

const mapStateToProps = state => {
    return state;
}
  
const mapDispatchToProps = {
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);