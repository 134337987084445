import React from 'react';
import { connect } from 'react-redux';
import { 
    Page, Card, Layout, DataTable, Button, CalloutCard, Checkbox, DisplayText
} from '@shopify/polaris';
import { getBuilders } from './../../actions/';
import { db } from './../../actions/settings';
import Loading from './loading';
import Stats from './stats';

class Home extends React.Component {
    
    state = {
        loading: true,
        builders: [],
        selectedItems: [],
        error: '',
        deleting: false
    }

    componentDidMount = () => {
        window.Intercom("boot", {
            app_id: "royh11t2",
            name: this.props.user.info.name,
            email: this.props.user.info.email
        });
        db.collection("Shops")
        .doc(this.props.user.info.id)
        .get()
        .then((res) => {
            if (res.exists) {
                getBuilders(this.props.user.info.id)
                .then((builders) => {
                    let builds = [];
                    builders.map((build) => {
                        builds.push([
                            build.name,
                            build.stagesLength,
                            <Checkbox checked={build.active} disabled />,
                            <Button size="slim" onClick={() => this.props.history.push(`/app/builds/form/${build.id}`)}>Edit</Button>,
                            <Button size="slim" primary onClick={() => {
                                var win = window.open(build.link, '_blank');
                                win.focus();
                            }}>View</Button>
                        ]);
                    })
                    this.setState({
                        builders: builds,
                        loading: false
                    });
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                        error: 'Unable to find your builds, please reload the page.'
                    });
                });       
            }
        })
        .catch((err) => console.log(err));
    }

    handleSelectionChange = (selectedItems) => {
        this.setState({selectedItems});
    };

    render() {
        const { loading, builders, deleting } = this.state;
        const bulkActions = [
            {
              content: 'Disable Builds',
              onAction: () => console.log('Todo: implement bulk add tags'),
            },
            {
              content: 'Delete Builds',
              onAction: () => console.log('Todo: implement bulk remove tags'),
            }
        ];
        if (loading) {
            return <Loading/>
        } else {
            return (
                <Page 
                    title="Home"
                    fullWidth={false}
                    separator
                    primaryAction={{
                        content: 'Create a Build',
                        onAction: () => window.location.href = "/app/builds/form"
                    }}
                >
                    <Layout>
                        <Layout.Section>
                            <div style={{marginBottom: 25+'px'}}>
                                <DisplayText size="large">Build-A-Box App</DisplayText>
                            </div>
                        </Layout.Section>
                    </Layout>
                    <Layout>                     
                        <Stats/>
                        <Layout.Section>
                            {builders.length > 0 &&
                            <Card title="Your Build-A-Box Pages" sectioned>
                                <DataTable
                                    columnContentTypes={[
                                        'text',
                                        'numeric',
                                        'text',
                                        'text',
                                        'text',
                                    ]}
                                    headings={[
                                        'Build Name',
                                        'Stages',
                                        'Active',
                                        'Edit',
                                        'View',
                                    ]}
                                    rows={this.state.builders}
                                    footerContent={`Showing ${this.state.builders.length} of ${this.state.builders.length} results`}
                                />
                            </Card>
                            }
                            {builders.length === 0 &&
                            <CalloutCard
                                title="Create your first Build"
                                illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                                primaryAction={{
                                    content: 'Get Started',
                                    onAction: () => this.props.history.push('/app/builds/form')
                                }}
                            >
                                <p>We'll then provide you with a link for your new build.</p>
                            </CalloutCard>
                            }
                        </Layout.Section>
                    </Layout>
                </Page>
            )
        }
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(Home);