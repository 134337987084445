import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { fb } from './../../actions/settings';
import { LoadingPage } from './../misc/loading';
import { ErrorPage } from './../auth/error';
import { confirmPlan, login } from './../../actions';

class Paid extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: ''
        }
    }

    componentDidMount = () => {
        confirmPlan(this.queryParams)
        .then((res) => {
            if (res.error) {
                this.setState({
                    loading: false,
                    error: res.message
                });
            } else {
                fb
                .auth()
                .signInWithEmailAndPassword(res.user.email, res.user.access)
                .then((user) => {
                    if (user) {
                        this.props.login(res.user);
                        this.props.history.push("/app/");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                        error: err.message
                    });
                });
            }
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                loading: false,
                error: err.message
            });
        });
    }

    render() {
        let { loading, error } = this.state;
        if (loading) {
            return <LoadingPage  shop=""/>;
        } else {
            return <ErrorPage message={error}/>;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Paid);