import { db } from './settings';

export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            info: null
        }
    }
}

export function stageState(state) {
    return {
        type: "STAGE_STATE",
        payload: {
            data: state
        }
    }
}

// normal functions

const API_URL = "https://us-central1-build-a-box.cloudfunctions.net/api";

export function checkAuth(query) {
    return new Promise((resolve, reject) => {
        let parameters = [];
        for (var key in query) {
            parameters.push(key + '=' + query[key]);
        }

        let message = parameters.sort().join(query.hmac ? '&' : '');
        const endpoint = `${API_URL}/auth?${message}`;
    
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function install(query) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state } = query;
        const endpoint = `${API_URL}/auth/install?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function checkPlan(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/auth/plan?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function confirmPlan(query) {
    return new Promise((resolve, reject) => {
        const { charge_id, id } = query;
        const endpoint = `${API_URL}/auth/confirm?id=${id}&charge_id=${charge_id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function getBuilders(id) {
    return new Promise((resolve, reject) => {
        db.collection('Builds')
        .where("shopId", "==", id)
        .get()
        .then((snap) => {
            let builders = [];
            snap.docs.map((doc) => {
                builders.push(doc.data());
            });
            resolve(builders);
        })
        .catch((err) => {
            console.log("getting builds", err);
            reject(err);
        });
    });
}

export function saveStage(js) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/builds/stage`;
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(js)
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}